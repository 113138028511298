"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBookViewingLocale = exports.getBookViewingDescription = void 0;
const getBookViewingDescription = (text, lang) => {
    let language = 'en';
    if (lang === 'jp') {
        language = 'ja';
    }
    else if (lang === 'tc') {
        language = 'zh-TW';
    }
    else if (lang === 'sc') {
        language = 'zh-CN';
    }
    else if (lang === 'kr') {
        language = 'kr';
    }
    const tempStr = text.replace('<p>', '').replace('</p>', '');
    try {
        const parsedText = JSON.parse(tempStr);
        return parsedText[language];
    }
    catch (err) {
        return tempStr;
    }
};
exports.getBookViewingDescription = getBookViewingDescription;
const getBookViewingLocale = (lang) => {
    let language = 'en';
    if (lang === 'jp') {
        language = 'ja';
    }
    else if (lang === 'tc') {
        language = 'zh-TW';
    }
    else if (lang === 'sc') {
        language = 'zh-CN';
    }
    else if (lang === 'kr') {
        language = 'kr';
    }
    return language;
};
exports.getBookViewingLocale = getBookViewingLocale;
