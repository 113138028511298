"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getXRegion = exports.getXLocale = void 0;
const getXLocale = (strs) => {
    if (strs && strs?.[1]) {
        return strs?.[1];
    }
    return 'en';
};
exports.getXLocale = getXLocale;
const getXRegion = (strs) => {
    if (strs && strs?.[2]) {
        return strs?.[2];
    }
    return 'hk';
};
exports.getXRegion = getXRegion;
