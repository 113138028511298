"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFieldErrorNames = void 0;
const getFieldErrorNames = (formikErrors) => {
    const transformObjectToDotNotation = (obj, prefix = '', result = []) => {
        Object.keys(obj).forEach((key) => {
            const value = obj[key];
            if (!value)
                return;
            const nextKey = prefix ? `${prefix}.${key}` : key;
            if (typeof value === 'object') {
                transformObjectToDotNotation(value, nextKey, result);
            }
            else {
                result.push(nextKey);
            }
        });
        return result;
    };
    return transformObjectToDotNotation(formikErrors);
};
exports.getFieldErrorNames = getFieldErrorNames;
