"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewingList = void 0;
const react_1 = __importStar(require("react"));
const ViewingCardRow_1 = require("./ViewingCardRow");
const Sentry = __importStar(require("@sentry/react"));
const BookingService_1 = require("../../services/BookingService");
const react_i18next_1 = require("react-i18next");
const Pagination_1 = require("../Pagination");
const ViewingList = (props) => {
    const { t } = (0, react_i18next_1.useTranslation)(["auth"]);
    const { setListLoading, isActive } = props;
    const [filter, setFilter] = (0, react_1.useState)({
        page: 0,
        limit: 5,
    });
    const [pageCount, setPageCount] = (0, react_1.useState)(1);
    const [currentPage, setCurrentPage] = (0, react_1.useState)(0);
    const [rooms, setRooms] = (0, react_1.useState)([]);
    const itemsPerPage = 5;
    (0, react_1.useEffect)(() => {
        getViewingListData();
    }, [filter]);
    const getViewingListData = (0, react_1.useCallback)(async () => {
        if (filter) {
            setListLoading(true);
            try {
                setCurrentPage(filter.page);
                const userViewings = await (0, BookingService_1.getUserViewings)(filter);
                setRooms(userViewings.rows);
                setPageCount(Math.ceil(userViewings.count / itemsPerPage));
            }
            catch (err) {
                // console.log(err);
                Sentry.captureException(err);
            }
            finally {
                setListLoading(false);
            }
        }
    }, [filter]);
    return (react_1.default.createElement(react_1.default.Fragment, null, isActive &&
        (rooms.length > 0 ? (react_1.default.createElement(react_1.default.Fragment, null,
            rooms?.map((room, index) => (react_1.default.createElement("div", { className: "room-card-row-container", key: index },
                react_1.default.createElement(ViewingCardRow_1.ViewingCardRow, { room: room })))),
            react_1.default.createElement(Pagination_1.Pagination, { totalPages: pageCount, pageOnChange: (page) => {
                    setListLoading(true);
                    setFilter((prev) => ({
                        ...prev,
                        page: page,
                    }));
                }, currentPage: currentPage }))) : (react_1.default.createElement("div", { className: "no-viewing-container d-flex flex-column justify-content-center align-items-center" },
            react_1.default.createElement("img", { className: "no-result-icon", src: "/images/property_not_found.svg?eed240f720c4aad1668ceaf7ff147c37", draggable: "false" }),
            react_1.default.createElement("div", { className: "description" }, t("You have not booked any viewings")))))));
};
exports.ViewingList = ViewingList;
