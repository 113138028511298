"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthContext = exports.authContextDefaults = void 0;
const react_1 = require("react");
exports.authContextDefaults = {
    authLoading: true,
    user: null,
    isAuthenticated: false,
    renewAuth: async () => null,
};
exports.AuthContext = (0, react_1.createContext)(exports.authContextDefaults);
