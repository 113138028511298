"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FullViewModal = void 0;
const react_1 = __importStar(require("react"));
const react_2 = require("swiper/react");
require("../../assets/scss/components/Modal/FullView.scss");
const swiper_1 = require("swiper");
const FullViewModal = ({ previewImages, onClose, previewIndex, floorPlanIndex }) => {
    const swiperRef = (0, react_1.useRef)();
    const hasFloorPlanBtn = floorPlanIndex === 0 || (floorPlanIndex && floorPlanIndex > -1);
    return (react_1.default.createElement("div", { className: "modal fade viewFullModal ", id: 'viewFullModal', "data-backdrop": false },
        react_1.default.createElement("div", { className: "file-view-container" },
            react_1.default.createElement("div", { className: `file-viewer-tool-bar ${hasFloorPlanBtn ? 'justify-content-between' : ''}` },
                hasFloorPlanBtn ? (react_1.default.createElement("button", { className: 'floor-plan-button', onClick: () => swiperRef.current?.slideTo(floorPlanIndex + 1) }, "FLOOR PLAN")) : null,
                react_1.default.createElement("div", { className: "d-flex flex-row" },
                    react_1.default.createElement("button", { className: "file-view-tool-bar-btn", onClick: () => onClose() },
                        react_1.default.createElement("img", { className: "page-close-icon", src: "/images/react/icons/close_white_2.svg" })))),
            react_1.default.createElement("div", { className: "file-view-wrapper" },
                react_1.default.createElement("div", { className: "swiper-container" },
                    react_1.default.createElement(react_2.Swiper, { initialSlide: previewIndex, loop: true, spaceBetween: 30, mousewheel: true, modules: [swiper_1.Navigation], navigation: true, className: 'full-view-swiper', onBeforeInit: (swiper) => {
                            swiperRef.current = swiper;
                        } }, previewImages.map((image, slideIndex) => (react_1.default.createElement(react_2.SwiperSlide, { key: slideIndex },
                        react_1.default.createElement("img", { src: image, draggable: false }))))))))));
};
exports.FullViewModal = FullViewModal;
