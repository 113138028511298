"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatBedroomProduct = exports.formatAddOnProduct = void 0;
const formatAddOnProduct = (product) => {
    const data = {
        name: product.productName,
        productId: product.Product.productACWId,
        image: product.image || product.Product.image || '',
        unit: product.unitOfMeasure || '',
        unitPrice: parseFloat(product.Product.unitPrice),
        quantity: 0,
        content: product.content,
    };
    return data;
};
exports.formatAddOnProduct = formatAddOnProduct;
const formatBedroomProduct = (product) => {
    const data = {
        name: product?.ProductTranslation?.productName || '',
        productId: product.productId,
        image: product?.ProductTranslation?.image,
        unit: product?.ProductTranslation?.unitOfMeasure,
        unitPrice: parseFloat(product.unitPrice),
        quantity: product.quantity,
        content: product?.ProductTranslation?.content,
    };
    return data;
};
exports.formatBedroomProduct = formatBedroomProduct;
