// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[13].oneOf[1].use[1]!../../../../../node_modules/react-toastify/dist/ReactToastify.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root{--toastify-toast-width:80%;--toastify-text-color-light:#000}", "",{"version":3,"sources":["webpack://./resources/js/react/assets/scss/Toastify.scss"],"names":[],"mappings":"AAQA,MACE,0BAAA,CACA,gCANF","sourcesContent":["@import 'react-toastify/dist/ReactToastify.css';\n\n// .Toastify {\n//   .Toastify__toast-container {\n//     width: 80%;\n//   }\n// }\n\n:root {\n  --toastify-toast-width: 80%;\n  --toastify-text-color-light: #000000;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
