"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ToastOptions = void 0;
exports.ToastOptions = {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: true,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: true,
    theme: "light",
};
