"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReservationList = void 0;
const react_1 = __importStar(require("react"));
const ReservationCardRow_1 = require("../Profile/ReservationCardRow");
const react_router_dom_1 = require("react-router-dom");
const ConfirmPopup_1 = require("../ConfirmPopup");
const Pagination_1 = require("../../components/Pagination");
const Sentry = __importStar(require("@sentry/react"));
const BookingService_1 = require("../../services/BookingService");
const react_i18next_1 = require("react-i18next");
const ReservationList = (props) => {
    const { t } = (0, react_i18next_1.useTranslation)(["auth"]);
    const { locale, region } = (0, react_router_dom_1.useParams)();
    const { setListLoading, isActive } = props;
    const [filter, setFilter] = (0, react_1.useState)({
        page: 0,
        limit: 5
    });
    const [currentPage, setCurrentPage] = (0, react_1.useState)(0);
    const [pageCount, setPageCount] = (0, react_1.useState)(1);
    const [rooms, setRooms] = (0, react_1.useState)([]);
    const itemsPerPage = 5;
    const [activeIndex, setActiveIndex] = (0, react_1.useState)(null);
    const [currentAction, setCurrentAction] = (0, react_1.useState)("extend");
    (0, react_1.useEffect)(() => {
        getReservationListData();
    }, [filter]);
    const getReservationListData = (0, react_1.useCallback)(async () => {
        if (filter) {
            setListLoading(true);
            try {
                setCurrentPage(filter.page);
                const userReservations = await (0, BookingService_1.getUserReservations)(filter);
                setRooms(userReservations.rows);
                setPageCount(Math.ceil(userReservations.count / itemsPerPage));
            }
            catch (err) {
                // // console.log(err);
                Sentry.captureException(err);
            }
            finally {
                setListLoading(false);
            }
        }
    }, [filter]);
    const goToDetail = (bedroomOfferID) => {
        window.location.href = `/${locale}/${region}/reservation-detail/${bedroomOfferID}`;
    };
    const goToOffer = (bedroomOfferID, offerRegion) => {
        if (offerRegion) {
            window.location.href = `/${locale}/${offerRegion}/checkout?bedroomOfferID=${bedroomOfferID}`;
        }
    };
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(ConfirmPopup_1.ConfirmPopup, { bedroomOfferID: activeIndex, afterSubmit: () => { getReservationListData(); $("#confirmPopup").modal("hide"); }, action: currentAction }),
        isActive && (rooms.length > 0
            ? react_1.default.createElement(react_1.default.Fragment, null,
                rooms?.map((room, index) => (react_1.default.createElement("div", { className: "room-card-row-container", key: index },
                    react_1.default.createElement(ReservationCardRow_1.ReservationCardRow, { room: room, goToDetail: () => goToDetail(room.bedroomOfferID), goToOffer: () => goToOffer(room.bedroomOfferID, room?.Room?.WebSiteProperty?.WebSiteProperty?.region), setActiveIndex: setActiveIndex, setCurrentAction: setCurrentAction })))),
                react_1.default.createElement(Pagination_1.Pagination, { totalPages: pageCount, pageOnChange: (page) => {
                        setListLoading(true);
                        setFilter((prev) => ({
                            ...prev,
                            'page': page
                        }));
                    }, currentPage: currentPage }))
            :
                react_1.default.createElement("div", { className: "no-reservation-container d-flex flex-column justify-content-center align-items-center" },
                    react_1.default.createElement("img", { className: "no-result-icon", src: "/images/property_not_found.svg?eed240f720c4aad1668ceaf7ff147c37", draggable: "false" }),
                    react_1.default.createElement("div", { className: "description" }, t("You have not reserved any room")),
                    react_1.default.createElement("button", { className: "explore-btn d-flex flex-row align-items-center justify-content-center", onClick: () => $('#searchModal').modal("show") },
                        react_1.default.createElement("div", { className: "back-btn-text" }, t("EXPLORE OTHER HOME TYPE")),
                        react_1.default.createElement("img", { className: "arrow", src: "/images/arrow_right.svg?6bbca23fc39c8f4938d53c3b433182b5", draggable: "false" }))))));
};
exports.ReservationList = ReservationList;
