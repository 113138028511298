"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadingReducer = exports.stepReducer = exports.renewalOfferReducer = void 0;
const CommonHelpers_1 = require("../helpers/CommonHelpers");
const RenewalContext_1 = require("./RenewalContext");
const i18next_1 = require("i18next");
function renewalOfferReducer(state, action) {
    const { type, payload } = action;
    switch (type) {
        case RenewalContext_1.renewalOfferActionType.update_offer:
            return {
                ...state,
                ...payload
            };
        case RenewalContext_1.renewalOfferActionType.stripeKeys:
            return {
                ...state,
                stripeKeys: payload
            };
        case RenewalContext_1.renewalOfferActionType.stayPeriod:
            return {
                ...state,
                stayPeriod: payload
            };
        case RenewalContext_1.renewalOfferActionType.payMethodDiscountPercent:
            return {
                ...state,
                payMethodDiscountPercent: payload
            };
        default: throw new Error(`No ${type} found in renewalOfferReducer`);
    }
}
exports.renewalOfferReducer = renewalOfferReducer;
function stepReducer(state, action) {
    const { type, payload } = action;
    const temp = { ...state, stepActionWarning: false };
    switch (type) {
        case RenewalContext_1.stepActionType.first:
            return {
                ...temp,
                activeStep: state.stepperOptions[0].value,
                activeStepLabel: state.stepperOptions[0].label,
                activeStepIndex: 0
            };
        case RenewalContext_1.stepActionType.to:
            if (payload && payload.targetStep) {
                return {
                    ...temp,
                    activeStep: payload.targetStep,
                    activeStepLabel: state.stepperOptions[(0, CommonHelpers_1.findStepIndex)(payload.targetStep, state.stepperOptions)].label,
                    activeStepIndex: (0, CommonHelpers_1.findStepIndex)(payload.targetStep, state.stepperOptions)
                };
            }
            else {
                throw new Error(`targetStep is missing`);
            }
        case RenewalContext_1.stepActionType.next:
            return {
                ...temp,
                activeStep: state.stepperOptions[state.activeStepIndex + 1].value,
                activeStepLabel: state.stepperOptions[state.activeStepIndex + 1].label,
                activeStepIndex: state.activeStepIndex + 1
            };
        case RenewalContext_1.stepActionType.previous:
            if (state.activeStep === 'payment' || state.activeStep === 'license_agreement') {
                return {
                    ...temp,
                    activeStep: state.stepperOptions[state.activeStepIndex - 1].value,
                    activeStepLabel: state.stepperOptions[state.activeStepIndex - 1].label,
                    activeStepIndex: state.activeStepIndex - 1
                };
            }
            else {
                return { ...temp };
            }
        case RenewalContext_1.stepActionType.update_options: {
            const index = (0, CommonHelpers_1.findStepIndex)("addon_requests", state.stepperOptions);
            const tempOptions = [...state.stepperOptions];
            if (payload && payload.stayPeriod && payload.stayPeriod === "shortStay") {
                tempOptions[(0, CommonHelpers_1.findStepIndex)("license_agreement", state.stepperOptions)].label = (0, i18next_1.t)('House Rules Agreement');
                if (index > -1) {
                    tempOptions.splice(index, 1);
                }
            }
            else if (payload && payload.stayPeriod && payload.stayPeriod === "longStay") {
                if (index > -1 && payload.region === "sg") {
                    tempOptions.splice(index, 1);
                }
            }
            else {
                throw new Error(`stayPeriod is missing to update step options`);
            }
            return {
                ...state,
                stepperOptions: tempOptions
            };
        }
        case RenewalContext_1.stepActionType.add:
            if (payload && payload.afterStep && payload.newStep) {
                return {
                    ...state,
                    stepperOptions: state.stepperOptions.splice((0, CommonHelpers_1.findStepIndex)(payload.afterStep, state.stepperOptions), 0, payload.newStep)
                };
            }
            else {
                throw new Error(`afterStep or newStep is missing`);
            }
        case RenewalContext_1.stepActionType.remove:
            if (payload && payload?.targetStep) {
                const removeIndex = (0, CommonHelpers_1.findStepIndex)(payload?.targetStep, state.stepperOptions);
                if (removeIndex > -1) {
                    state.stepperOptions.splice(removeIndex, 1);
                    return {
                        ...state,
                        stepperOptions: state.stepperOptions
                    };
                }
                else
                    throw new Error(`Step ${payload?.targetStep} not found`);
            }
            else {
                throw new Error(`targetStep is missing`);
            }
        case RenewalContext_1.stepActionType.cancelStepAction:
            return { ...temp };
        default: throw new Error(`No ${type} found in stepReducer`);
    }
}
exports.stepReducer = stepReducer;
function loadingReducer(state, action) {
    const { type, payload } = action;
    const temp = { ...state, };
    temp[type] = payload;
    for (const loading in temp) {
        if (loading !== "globalLoading" && temp[loading]) {
            return {
                ...temp, globalLoading: true
            };
        }
    }
    return { ...temp, globalLoading: false };
}
exports.loadingReducer = loadingReducer;
