"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailField = void 0;
const react_1 = __importStar(require("react"));
require("../../assets/scss/components/Input.scss");
const formik_1 = require("formik");
const react_i18next_1 = require("react-i18next");
function EmailField(props) {
    const [field, meta, helpers] = (0, formik_1.useField)(props);
    const { name, placeholder, disabled, className, focus, isSending, countDown, sendOnClick, inputOnChange, } = props;
    const { t } = (0, react_i18next_1.useTranslation)(["auth"]);
    const ref = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(() => {
        if (focus) {
            ref.current?.focus();
        }
    }, [focus]);
    return (react_1.default.createElement("div", { className: `${name} input-field-container ${className} ${disabled ? "disabled" : ""}` },
        react_1.default.createElement("input", { ref: ref, ...field, onChange: (e) => {
                if (inputOnChange) {
                    inputOnChange(e);
                }
                else {
                    helpers.setValue(e.target.value);
                }
            }, value: field.value || "", disabled: disabled, type: "email", placeholder: ' ', autoComplete: "new-password", className: `email-input-field ${isSending ? 'sending-code' : ''}` }),
        react_1.default.createElement("label", { className: `${name} placeholder` }, placeholder),
        isSending ? (react_1.default.createElement("div", { className: "resend-reminder" },
            t("Resend"),
            " (",
            countDown,
            " ",
            t("secs"),
            ")")) : (react_1.default.createElement("button", { className: `send-button ${!field.value || !!meta.error ? 'disabled' : ''}`, type: "button", onClick: sendOnClick, disabled: !field.value || !!meta.error }, t('Send'))),
        meta.touched && meta.error ? (react_1.default.createElement("div", { className: "error-message" }, t(meta.error))) : null));
}
exports.EmailField = EmailField;
