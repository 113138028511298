"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PdfReader = void 0;
const react_1 = __importStar(require("react"));
const react_pdf_1 = require("react-pdf");
react_pdf_1.pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${react_pdf_1.pdfjs.version}/pdf.worker.js`;
exports.PdfReader = (0, react_1.memo)((props) => {
    const { pdfSrc, width, isWithCredentials } = props;
    const [numPages, setPageNumber] = (0, react_1.useState)(0);
    const onDocumentLoadSuccess = ({ numPages }) => {
        setPageNumber(numPages);
    };
    const renderPdf = (0, react_1.useMemo)(() => {
        return (react_1.default.createElement(react_pdf_1.Document, { file: {
                url: pdfSrc,
                withCredentials: isWithCredentials
            }, onLoadSuccess: onDocumentLoadSuccess, onLoadError: (e) => console.log(e), className: "pdf-container" }, Array.from(new Array(numPages), (el, index) => (react_1.default.createElement(react_pdf_1.Page, { key: `page_${index + 1}`, pageNumber: index + 1, className: "pdf-page", renderMode: "svg" })))));
    }, [numPages]);
    const renderMobilePdf = (0, react_1.useMemo)(() => {
        return (react_1.default.createElement(react_pdf_1.Document, { file: {
                url: pdfSrc,
                withCredentials: isWithCredentials
            }, onLoadSuccess: onDocumentLoadSuccess, onLoadError: (e) => console.log(e), className: "pdf-container" }, Array.from(new Array(numPages), (el, index) => (react_1.default.createElement(react_pdf_1.Page, { key: `page_${index + 1}`, pageNumber: index + 1, className: "pdf-page", renderMode: "svg", scale: 0.6 })))));
    }, [numPages]);
    if (width < 700) {
        return renderMobilePdf;
    }
    else {
        return renderPdf;
    }
});
