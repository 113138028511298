"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScrollToFieldError = void 0;
const react_1 = require("react");
const formik_1 = require("formik");
const FormHelper_1 = require("../../helpers/FormHelper");
const ScrollToFieldError = ({ scrollBehavior = { behavior: 'smooth', block: 'center' }, formErrors }) => {
    const { submitCount, isValid, errors } = (0, formik_1.useFormikContext)();
    (0, react_1.useEffect)(() => {
        if (isValid && !formErrors)
            return;
        const fieldErrorNames = (0, FormHelper_1.getFieldErrorNames)(errors || formErrors && Object.keys(formErrors));
        if (fieldErrorNames.length <= 0)
            return;
        const element = document.querySelector(`input[name='${fieldErrorNames[0]}']`);
        const select = document.getElementsByClassName(`${fieldErrorNames[0]} select-group`);
        const upload = document.getElementsByClassName(`${fieldErrorNames[0]} identity-upload-button`);
        if (!element && !select && !upload)
            return;
        if (element) {
            element.scrollIntoView(scrollBehavior);
        }
        else if (select?.[0]) {
            select[0].scrollIntoView(scrollBehavior);
        }
        else if (upload?.[0]) {
            upload[0].scrollIntoView(scrollBehavior);
        }
    }, [submitCount, formErrors]);
    return null;
};
exports.ScrollToFieldError = ScrollToFieldError;
