"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JP_LOCALE = exports.SC_LOCALE = exports.TC_LOCALE = exports.Custom_SC_Locale = exports.Custom_TC_Locale = void 0;
exports.Custom_TC_Locale = {
    weekdays: {
        shorthand: ["週日", "週一", "週二", "週三", "週四", "週五", "週六"],
        longhand: [
            "星期日",
            "星期一",
            "星期二",
            "星期三",
            "星期四",
            "星期五",
            "星期六",
        ],
    },
    months: {
        shorthand: [
            "一月",
            "二月",
            "三月",
            "四月",
            "五月",
            "六月",
            "七月",
            "八月",
            "九月",
            "十月",
            "十一月",
            "十二月",
        ],
        longhand: [
            "一月",
            "二月",
            "三月",
            "四月",
            "五月",
            "六月",
            "七月",
            "八月",
            "九月",
            "十月",
            "十一月",
            "十二月",
        ],
    },
    rangeSeparator: " - ",
    weekAbbreviation: "週",
    scrollTitle: "滾動切換",
    toggleTitle: "點擊切換 12/24 小時時制",
};
exports.Custom_SC_Locale = {
    weekdays: {
        shorthand: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
        longhand: [
            "星期日",
            "星期一",
            "星期二",
            "星期三",
            "星期四",
            "星期五",
            "星期六",
        ],
    },
    months: {
        shorthand: [
            "一月",
            "二月",
            "三月",
            "四月",
            "五月",
            "六月",
            "七月",
            "八月",
            "九月",
            "十月",
            "十一月",
            "十二月",
        ],
        longhand: [
            "一月",
            "二月",
            "三月",
            "四月",
            "五月",
            "六月",
            "七月",
            "八月",
            "九月",
            "十月",
            "十一月",
            "十二月",
        ],
    },
    rangeSeparator: " - ",
    weekAbbreviation: "周",
    scrollTitle: "滚动切换",
    toggleTitle: "点击切换 12/24 小时时制",
};
exports.TC_LOCALE = {
    setText: "確定",
    cancelText: "取消",
    clearText: "清除",
    selectedText: "{count} 已選擇",
    dateFormat: "YYYY.MM.DD.",
    dateFormatLong: "YYYY年 MMM D日, ddd",
    dayNames: ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"],
    dayNamesShort: ["日", "一", "二", "三", "四", "五", "六"],
    dayNamesMin: ["日", "一", "二", "三", "四", "五", "六"],
    dayText: '日',
    delimiter: '.',
    hourText: '小時',
    minuteText: '分鐘',
    monthNames: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
    monthNamesShort: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
    monthText: '月份',
    secText: '第二個',
    timeFormat: 'H:mm',
    yearText: '年份',
    nowText: '現在',
    pmText: '下午',
    amText: '上午',
    firstDay: 1,
    dateText: '日期',
    timeText: '時間',
    todayText: '今天',
    prevMonthText: '上個月',
    nextMonthText: '下個月',
    prevYearText: '上一年',
    nextYearText: '明年',
    closeText: '關閉',
    eventText: '事件',
    eventsText: '事件',
    allDayText: '全天',
    noEventsText: '沒有事件',
    moreEventsText: '{count} 更多',
};
exports.SC_LOCALE = {
    setText: "确定",
    cancelText: "取消",
    clearText: "清除",
    selectedText: "{count} 已选择",
    dateFormat: "YYYY.MM.DD.",
    dateFormatLong: "YYYY年 MMM D日, ddd",
    dayNames: ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"],
    dayNamesShort: ["日", "一", "二", "三", "四", "五", "六"],
    dayNamesMin: ["日", "一", "二", "三", "四", "五", "六"],
    dayText: '日',
    delimiter: '.',
    hourText: '小时',
    minuteText: '分钟',
    monthNames: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
    monthNamesShort: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
    monthText: '月份',
    secText: '第二个',
    timeFormat: 'H:mm',
    yearText: '年份',
    nowText: '现在',
    pmText: '下午',
    amText: '上午',
    firstDay: 1,
    dateText: '日期',
    timeText: '时间',
    todayText: '今天',
    prevMonthText: '上个月',
    nextMonthText: '下个月',
    prevYearText: '上一年',
    nextYearText: '明年',
    closeText: '关闭',
    eventText: '事件',
    eventsText: '事件',
    allDayText: '全天',
    noEventsText: '没有事件',
    moreEventsText: '{count} 更多',
};
exports.JP_LOCALE = {
    setText: "確定",
    cancelText: "取消",
    clearText: "削除",
    selectedText: "{count} 個選択されました",
    dateFormat: "YYYY.MM.DD.",
    dateFormatLong: "YYYY年/MM月/ DD日",
    dayNames: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
    dayNamesShort: ['日曜', '月曜', '火曜', '水曜', '木曜', '金曜', '土曜'],
    dayNamesMin: ['日', '月', '火', '水', '木', '金', '土'],
    dayText: '日',
    delimiter: '.',
    hourText: '時',
    minuteText: '分',
    monthNames: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
    monthNamesShort: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
    monthText: '月',
    secText: '秒',
    timeFormat: 'H:mm',
    yearText: '年',
    nowText: '现在',
    pmText: 'pm',
    amText: 'am',
    firstDay: 1,
    dateText: '日',
    timeText: '时',
    todayText: '今日',
    prevMonthText: '前の月',
    nextMonthText: '次の月',
    prevYearText: '前の年',
    nextYearText: '次の年',
    closeText: '閉じる',
    eventText: 'イベント',
    eventsText: 'イベント',
    allDayText: '終日',
    noEventsText: 'イベントがありません',
    moreEventsText: 'さらに見る',
};
