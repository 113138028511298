"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HCaptchaSiteKey = exports.BOOK_VIEWING_IMAGE_URL = exports.BOOK_VIEWING_API_URL = exports.RENEWAL_FLOW_URL = exports.QFPAY_ENV = exports.QFPAY_REGION = exports.QFPAY_API_VER = exports.QFPAY_URL = exports.ENABLE_JP_DIRECT_BOOKING = exports.ENABLE_SG_DIRECT_BOOKING = exports.ENABLE_HK_DIRECT_BOOKING = exports.PAGE_SIZE = exports.RESOURCES_URL = exports.APPICIDEA_IMAGE_URL = exports.IMAGE_URL = exports.API_URL = void 0;
exports.API_URL = process.env.MIX_APP_API_URL || 'https://weaveliving-app-dev.appicidea.com';
exports.IMAGE_URL = process.env.MIX_IMAGE_URL || 'https://weaveliving-website-dev1.appicidea.com';
exports.APPICIDEA_IMAGE_URL = process.env.MIX_APPICIDEA_IMAGE_URL || 'https://static.appicidea.com';
exports.RESOURCES_URL = process.env.MIX_RESOURCES_URL || 'https://weaveliving-website-dev1.appicidea.com';
exports.PAGE_SIZE = 20;
exports.ENABLE_HK_DIRECT_BOOKING = (process.env.MIX_IS_ENABLE_HK_DIRECT_BOOKING || 'true') === 'true';
exports.ENABLE_SG_DIRECT_BOOKING = (process.env.MIX_IS_ENABLE_SG_DIRECT_BOOKING || 'true') === 'true';
exports.ENABLE_JP_DIRECT_BOOKING = (process.env.MIX_IS_ENABLE_JP_DIRECT_BOOKING || 'true') === 'true';
// QFPAY
exports.QFPAY_URL = process.env.MIX_QFPAY_URL || 'https://centinelapistag.cardinalcommerce.com';
exports.QFPAY_API_VER = process.env.MIX_QFPAY_API_VER || '/V1/Cruise/Collect';
exports.QFPAY_REGION = process.env.MIX_QFPAY_REGION || 'hk';
exports.QFPAY_ENV = process.env.MIX_QFPAY_ENV || 'prod';
// renewal flow
// prod: https://www.weave-living.com/mobileapp/renewal-success
// dev: https://weaveliving-app-dev.appicidea.com/mobileapp/renewal-success
exports.RENEWAL_FLOW_URL = process.env.MIX_RENEWAL_FLOW_URL || 'https://weaveliving-app-dev.appicidea.com';
// booking
exports.BOOK_VIEWING_API_URL = process.env.MIX_BOOKING_API_URL || 'https://weaveliving-app-dev.appicidea.com';
exports.BOOK_VIEWING_IMAGE_URL = process.env.MIX_BOOK_VIEWING_IMAGE_URL || 'https://viewing.weave-living.com/';
// hcaptcha
exports.HCaptchaSiteKey = process.env.MIX_H_CAPTCHA_SITE_KEY || '4f8fdc56-d81f-47b6-8c2f-e95bacbfb540';
