"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IdentificationOptions = exports.PaymentOptionOptions = exports.OCRPassportAnalyzResultDocFields = exports.OCRAnalyzResultDocFields = exports.PriceListTypeOption = void 0;
var PriceListTypeOption;
(function (PriceListTypeOption) {
    PriceListTypeOption[PriceListTypeOption["< 1 MONTH"] = 557130000] = "< 1 MONTH";
    PriceListTypeOption[PriceListTypeOption["1-5 MONTHS"] = 557130001] = "1-5 MONTHS";
    PriceListTypeOption[PriceListTypeOption["6-11 MONTHS"] = 557130002] = "6-11 MONTHS";
    PriceListTypeOption[PriceListTypeOption["12+ MONTHS"] = 557130003] = "12+ MONTHS";
})(PriceListTypeOption = exports.PriceListTypeOption || (exports.PriceListTypeOption = {}));
exports.OCRAnalyzResultDocFields = [
    "Date of Birth",
    "Date of Registration",
    "ID Number",
    "ID Type",
    "ID Type TC",
    "Month Year of First Registration",
    "Name",
    "Name TC",
    "Name TC Commercial Code",
    "Sex",
    "Symbols",
];
exports.OCRPassportAnalyzResultDocFields = [
    'CountryRegion',
    'DateOfBirth',
    'DateOfExpiration',
    'DateOfIssue',
    'DocumentNumber',
    'DocumentType',
    'FirstName',
    'IssuingAuthority',
    'LastName',
    'MachineReadableZone',
    'Nationality',
    'PlaceOfBirth',
    'Sex',
    'Name',
    'ID Number',
    'Date of Birth',
];
// Validating Student Cert End
var PaymentOptionOptions;
(function (PaymentOptionOptions) {
    PaymentOptionOptions[PaymentOptionOptions["Short Stay"] = 557130000] = "Short Stay";
    PaymentOptionOptions[PaymentOptionOptions["Monthly"] = 557130001] = "Monthly";
    PaymentOptionOptions[PaymentOptionOptions["Prepaid"] = 557130002] = "Prepaid";
})(PaymentOptionOptions = exports.PaymentOptionOptions || (exports.PaymentOptionOptions = {}));
var IdentificationOptions;
(function (IdentificationOptions) {
    IdentificationOptions["Passport"] = "557130000";
    IdentificationOptions["HKID"] = "557130001";
    IdentificationOptions["Two-way Permit (CN):"] = "557130002";
    IdentificationOptions["National Registration (SG)"] = "557130003";
    IdentificationOptions["Individual Number (JP)"] = "557130004";
    IdentificationOptions["Resident Registration (JP)"] = "557130005";
    IdentificationOptions["Resident Registration (KR)"] = "557130006";
    IdentificationOptions["Driver\u2019s License (SG)"] = "557130007";
    IdentificationOptions["Driver\u2019s License (JP)"] = "557130008";
    IdentificationOptions["Driver\u2019s License (KR)"] = "557130009";
})(IdentificationOptions = exports.IdentificationOptions || (exports.IdentificationOptions = {}));
