"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.bookingTRPC = exports.batchTRPC = void 0;
const client_1 = require("@trpc/client");
const config_1 = require("../../config");
const tRPC = (0, client_1.createTRPCUntypedClient)({
    links: [
        (0, client_1.httpLink)({
            url: config_1.BOOK_VIEWING_API_URL + '/api/trpc/public',
        }),
    ],
});
exports.default = tRPC;
exports.batchTRPC = (0, client_1.createTRPCUntypedClient)({
    links: [
        (0, client_1.httpBatchLink)({
            url: config_1.BOOK_VIEWING_API_URL + '/api/trpc/public',
        }),
    ],
});
exports.bookingTRPC = (0, client_1.createTRPCUntypedClient)({
    links: [
        (0, client_1.httpBatchLink)({
            url: config_1.BOOK_VIEWING_API_URL + '/api/trpc/bookings',
        }),
    ],
});
