"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScrollToError = void 0;
const react_1 = require("react");
exports.ScrollToError = (0, react_1.memo)(({ isValid, submitCount, isValidating, isSubmitting, errors, setHROpen, setLAOpen, isHROpen, isLAOpen, disabled = false }) => {
    const scrollToError = (0, react_1.useCallback)(() => {
        if (!disabled) {
            const errEle = document.getElementsByName(Object.keys(errors)[0]);
            errEle[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [errors, disabled]);
    const checkSubmission = (0, react_1.useCallback)(() => {
        // new submission
        if (errors.isAgreementChecked || errors.isLongStayCancelChecked || errors.isLongStayExtensionChecked) {
            isLAOpen ? scrollToError() : setLAOpen();
        }
        else {
            isHROpen ? scrollToError() : setHROpen();
        }
    }, [submitCount, errors, isHROpen, isLAOpen]);
    (0, react_1.useEffect)(() => {
        // has error
        if (!isValid && !isValidating && submitCount !== 0 && !isSubmitting && Object.keys(errors).length) {
            checkSubmission();
        }
    }, [isValidating, isValid, isSubmitting, isHROpen, isLAOpen, errors]);
    return null;
});
