"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResidnetFormItem = exports.PREPAID_DAYS = exports.STEPPER_STYLE = exports.STEPPER_CONNECTOR_STYLE = void 0;
const STEPPER_CONNECTOR_STYLE = (width, fullWidth = true) => {
    return {
        disabledColor: "#D1D1D1",
        activeColor: "#496b62",
        completedColor: "#496b62",
        size: !fullWidth ? "0.2px" : width <= 550 ? "0.8px" : "1px",
        style: "dashed",
    };
};
exports.STEPPER_CONNECTOR_STYLE = STEPPER_CONNECTOR_STYLE;
const STEPPER_STYLE = (width, fullWidth = true) => {
    return {
        activeBgColor: "transparent",
        activeTextColor: "#496b62",
        completedBgColor: "transparent",
        completedTextColor: "#496b62",
        inactiveBgColor: "transparent",
        inactiveTextColor: "#D1D1D1",
        size: !fullWidth ? "12px" : width <= 550 ? "25px" : "40px",
        circleFontSize: !fullWidth ? "6px" : width <= 550 ? "12px" : "20px",
        labelFontSize: "14px",
        borderRadius: "40px",
        fontWeight: "700",
    };
};
exports.STEPPER_STYLE = STEPPER_STYLE;
exports.PREPAID_DAYS = 7;
exports.ResidnetFormItem = {
    specialRequest: "",
    contact: {
        firstName: "",
        lastName: "",
        countryCode: "852",
        phone: "",
        email: "",
        identityDocument: undefined,
        identityCardNumber: "",
    },
    residents: [],
    isBookingForOthers: false,
    isShortStay: false,
};
