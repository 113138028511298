"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBookingByUID = exports.reserveSlot = exports.getBookViewingEvents = exports.getScheduleSlots = void 0;
const index_1 = __importStar(require("./index"));
const getScheduleSlots = async (params, email) => {
    const result = await index_1.default.query('slots.getSchedule', {
        'json': { ...params },
        'meta': { values: { duration: ["undefined"], email: params.email ? undefined : [email ? email : 'undefined'] } },
    });
    return result;
};
exports.getScheduleSlots = getScheduleSlots;
const getBookViewingEvents = async (params) => {
    const result = await index_1.batchTRPC.query('event', {
        'json': { ...params },
    });
    return result;
};
exports.getBookViewingEvents = getBookViewingEvents;
const reserveSlot = async (params) => {
    const result = await index_1.default.mutation('slots.reserveSlot', {
        'json': { ...params },
    });
    return result;
};
exports.reserveSlot = reserveSlot;
const getBookingByUID = async (uid) => {
    const result = await index_1.bookingTRPC.query('getByUid', {
        'json': { uid },
    });
    return result;
};
exports.getBookingByUID = getBookingByUID;
