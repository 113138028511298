"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCurrentPage = void 0;
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useCurrentPage = () => {
    const param = (0, react_router_dom_1.useParams)();
    const currentPage = (0, react_1.useRef)({
        homePage: false,
        subBrand: undefined,
        homeType: undefined,
    });
    const checkCurrentPage = (0, react_1.useCallback)(() => {
        const arr = param["*"]?.split("/");
        if (arr && arr?.length > 1) {
            currentPage.current.subBrand = arr[0];
            currentPage.current.homeType = arr[1];
        }
        else if (arr && arr[0] !== "") {
            currentPage.current.subBrand = arr[0];
        }
        else {
            currentPage.current.homePage = true;
        }
    }, [param]);
    (0, react_1.useEffect)(() => {
        checkCurrentPage();
    }, [param]);
    return currentPage.current;
};
exports.useCurrentPage = useCurrentPage;
