"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JP_UNIVERSITIES = exports.HK_UNIVERSITIES = exports.STUDENT_VALIDATION_PHASE = void 0;
exports.STUDENT_VALIDATION_PHASE = [
    "Notice of Offer",
    "pleased to offer",
    "letter of admission",
    "study programme",
    "Mode of Study",
    "Field of Study",
    "programme code",
    "grant you admission to the College",
    "offer you admission to the University",
    "offer of admission",
    "official admission offer letter",
    "excited to welcome you",
    "pleasure to confirm your enrollment",
    "pleased to offer you admission",
    "pleased to offer you a place",
    "programme has been approved",
    "Congratulations on being admitted",
    "your admission to the full-time Master",
    "Admission as an Exchange Student",
];
exports.HK_UNIVERSITIES = [
    "The University of Hong Kong",
    "The Chinese University of Hong Kong",
    "The Hong Kong University of Science and Technology",
    "The Hong Kong Polytechnic University",
    "City University of Hong Kong",
    "The Education University of Hong Kong",
    "Hong Kong Baptist University",
    "Lingnan University",
];
exports.JP_UNIVERSITIES = [
    'Ochanomizu University',
    'Tokyo Gakugei University',
    'Tokyo Institute of Technology',
    'Tokyo Medical and Dental University',
    'Tokyo University of Agriculture and Technology',
    'Tokyo University of Foreign Studies',
    'Tokyo University of Marine Science and Technology',
    'Tokyo University of the Arts',
    'University of Electro-Communications',
    'University of Tokyo',
    'University of Tsukuba',
    'Advanced Institute of Industrial Technology',
    'Tokyo Metropolitan University',
    'Hitotsubashi University',
    'Aoyama Gakuin University',
    'Asia University',
    'Atomi University',
    'Bunka Gakuen University',
    'Bunkyo Gakuin University',
    'Chuo University',
    'Daito Bunka University',
    'Ferris University',
    'Gakushuin University',
    "Gakushuin Women's College",
    'Globis University Graduate School of Management',
    'Hosei University',
    'Hoshi University',
    'International Christian University',
    'International College for Postgraduate Buddhist Studies',
    'Japan College of Social Work',
    'Japan Lutheran College',
    'Japan Professional School of Education',
    "Japan Women's University",
    "Japan Women's College of Physical Education",
    'Jikei University School of Medicine',
    "Jissen Women's University",
    'Josai International University in Kioi',
    'Joshibi University of Art and Design',
    'Juntendo University',
    'Kaetsu University',
    'Kagawa Nutrition University',
    'Kanazawa Institute of Technology',
    "Kawamura Gakuen Woman's University",
    'Keio University',
    'Keisen University',
    'Kitasato University',
    'Kogakuin University',
    'Kokugakuin University',
    'Kokushikan University',
    'Komazawa University',
    'Kunitachi College of Music',
    'Kyorin University',
    'Kyoritsu College of Pharmacy',
    "Kyoritsu Women's University",
    'Meiji University',
    'Meisei University',
    'Mejiro University',
    'Musashi University',
    'Musashino University',
    'Nihon Bunka University',
    'Nihon University',
    'Nippon Medical School',
    'Nippon Sport Science University',
    'Nippon Veterinary and Life Science University',
    'Nishogakusha University',
    'Obirin University',
    "Otsuma Women's University",
    'Rikkyo University',
    'Rissho University',
    'Sanno University',
    'Seibo College',
    'Seijo University',
    'Seikei University',
    'Seisen University',
    'Senshu University',
    'Shibaura Institute of Technology',
    'Shiraume Gakuen University',
    'Shirayuri University',
    'Showa University',
    'Soka University',
    'Sophia University',
    "St. Luke's College of Nursing",
    'Sugino Fashion College',
    'Surugadai University',
    'Takachiho University',
    'Takushoku University',
    'Taisho University',
    'Tama University',
    'Tamagawa University',
    'Teikyo University',
    'Temple University',
    'The Nippon Dental University',
    'The Japanese Red Cross College of Nursing',
    'Toho University',
    'Toho Gakuen School of Music',
    'Tokai University',
    'Tokyo City University',
    'Tokyo College of Music',
    'Tokyo Denki University',
    'Tokyo Fuji University',
    'Tokyo Future University',
    'Tokyo Health Care University',
    'Tokyo Jogakkan College',
    'Tokyo Junshin University',
    'Tokyo Kasei University',
    'Tokyo Keizai University',
    'Tokyo Medical University',
    'Tokyo Polytechnic University',
    'Tokyo Seiei College',
    'Tokyo University of Agriculture',
    'Tokyo University of Pharmacy and Life Sciences',
    'Tokyo University of Science',
    'Tokyo University of Technology',
    "Tokyo Woman's Christian University",
    "Tokyo Women's College of Physical Education",
    "Tokyo Women's Medical University",
    'Tokyo Zokei University',
    'Toyo University',
    'Tsuda College',
    'Ueno Gakuen University',
    'University of the Sacred Heart',
    'Sacred Heart Wako University',
    'Waseda University',
];
