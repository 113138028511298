"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkJpFooterCommercialDisclosure = void 0;
const checkJpFooterCommercialDisclosure = (acw_property_id) => {
    const commercialDisclosureAlterna15 = document.getElementById('commercial-disclosure-alterna-15');
    const commercialDisclosureTMK2 = document.getElementById('commercial-disclosure-tmk-2');
    const commercialDisclosureAlterna22 = document.getElementById('commercial-disclosure-alterna-22');
    if (commercialDisclosureAlterna15) {
        commercialDisclosureAlterna15.style.display = 'none !important';
    }
    if (commercialDisclosureTMK2) {
        commercialDisclosureTMK2.style.display = 'none !important';
    }
    if (commercialDisclosureAlterna22) {
        commercialDisclosureAlterna22.style.display = 'none !important';
    }
    if (acw_property_id === 'WPWP' ||
        acw_property_id === 'WPMN' ||
        acw_property_id === 'WPHK' ||
        acw_property_id === 'WPRY' ||
        acw_property_id === 'WPAK' ||
        acw_property_id === 'WPMO') {
        if (commercialDisclosureAlterna15) {
            commercialDisclosureAlterna15.style.display = 'flex';
        }
    }
    if (acw_property_id === 'WPUS' ||
        acw_property_id === 'WPAS') {
        if (commercialDisclosureTMK2) {
            commercialDisclosureTMK2.style.display = 'flex';
        }
    }
    if (acw_property_id === 'WPKE') {
        if (commercialDisclosureAlterna22) {
            commercialDisclosureAlterna22.style.display = 'flex';
        }
    }
};
exports.checkJpFooterCommercialDisclosure = checkJpFooterCommercialDisclosure;
