"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StayInformationStep = void 0;
const react_1 = __importStar(require("react"));
const EventDescription_1 = require("./EventDescription");
const StayInformationForm_1 = require("./StayInformationForm");
const AuthRow_1 = require("./AuthRow");
const LoginForm_1 = require("./LoginForm");
const SignUpForm_1 = require("./SignUpForm");
const react_i18next_1 = require("react-i18next");
const react_router_dom_1 = require("react-router-dom");
const StayInformationStep = ({ form, event, selectedSlot, originSlot, loading, user, onSubmit, onLogin, onSignUp, }) => {
    const { region } = (0, react_router_dom_1.useParams)();
    const [step, setStep] = (0, react_1.useState)(null);
    const { t } = (0, react_i18next_1.useTranslation)(["search"]);
    return (react_1.default.createElement("div", { className: "View-date-step-container" },
        react_1.default.createElement(EventDescription_1.EventDescription, { event: event, selectedSlot: selectedSlot, originSlot: originSlot }),
        !originSlot ? react_1.default.createElement(AuthRow_1.AuthRow, { user: user, setStep: setStep }) : null,
        react_1.default.createElement("div", { className: "contact-reminder" },
            t("viewing_reminder_1"),
            " ",
            region === "hk" ? (react_1.default.createElement("a", { href: "mailto:live.hk@weave-living.com", className: "contact-reminder-email" }, "live.hk@weave-living.com")) : null,
            " ",
            region === "sg" ? (react_1.default.createElement("a", { href: "mailto:live.sg@weave-living.com", className: "contact-reminder-email" }, "live.sg@weave-living.com")) : null,
            " ",
            region === "jp" ? (react_1.default.createElement("a", { href: "mailto:live.jp@weave-living.com", className: "contact-reminder-email" }, "live.jp@weave-living.com")) : null,
            " ",
            region === "kr" ? (react_1.default.createElement("a", { href: "mailto:live.kr@weave-living.com", className: "contact-reminder-email" }, "live.kr@weave-living.com")) : null,
            " ",
            t("viewing_reminder_2")),
        !user && step === "login" && !originSlot && (react_1.default.createElement(LoginForm_1.LoginForm, { onLogin: onLogin })),
        !user && step === "signup" && !originSlot && (react_1.default.createElement(SignUpForm_1.SignUpForm, { onSignUp: onSignUp })),
        user || originSlot || step === "guest" ? (react_1.default.createElement(StayInformationForm_1.StayInformationForm, { form: form, event: event, selectedSlot: selectedSlot, loading: loading, isGuest: step === "guest", onSubmit: onSubmit })) : null));
};
exports.StayInformationStep = StayInformationStep;
