"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentDetail = void 0;
const react_1 = __importStar(require("react"));
const react_i18next_1 = require("react-i18next");
const BookingHelper_1 = require("../../helpers/BookingHelper");
const CommonHelpers_1 = require("../../helpers/CommonHelpers");
require("../../assets/scss/components/PaymentLink/PaymentDetail.scss");
const moment_1 = require("../../hooks/moment");
const react_router_dom_1 = require("react-router-dom");
const PaymentDetail = (props) => {
    const { region } = (0, react_router_dom_1.useParams)();
    const { invoiceNumber, data, propertyBooking, paymentDiscount, total, invoiceItems } = props;
    const { t } = (0, react_i18next_1.useTranslation)(['auth', 'checkout']);
    const { localMoment } = (0, moment_1.useMoment)();
    const subbrand = propertyBooking.Room?.WebSiteProperty?.WebSiteProperty?.category;
    const currency = (0, BookingHelper_1.getCurrency)(propertyBooking?.Room?.WebSiteRoom?.region || "hk");
    return react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement("div", { className: "payment-detail-container d-flex flex-column" },
            react_1.default.createElement("div", { className: "page-img-container d-flex" },
                react_1.default.createElement("img", { className: "page-img", src: '/images/home-whyweave-3.jpg', onError: e => e.currentTarget.src = '/images/home-whyweave-3.jpg' }),
                react_1.default.createElement("img", { src: `/images/subbrand/${(0, CommonHelpers_1.Capitalize)(subbrand)}.svg`, className: "position-absolute brand-tag" })),
            react_1.default.createElement("div", { className: "page-info-container" },
                react_1.default.createElement("div", { className: "page-info brief" },
                    react_1.default.createElement("div", { className: "invoice-info-container d-flex flex-column" },
                        react_1.default.createElement("div", { className: "invoice-info-title d-flex justify-content-between align-items-center" },
                            react_1.default.createElement("div", { className: "title" }, data?.paymentStatus === "Paid" ? "Receipt" : "Invoice"),
                            data?.paymentStatus === "Paid" ? null : (react_1.default.createElement("div", { className: `${data?.paymentStatus && data?.paymentStatus.toLowerCase()} status-label-contaienr d-flex justify-content-center align-items-center` },
                                react_1.default.createElement("div", { className: "status-label" }, data?.paymentStatus)))),
                        react_1.default.createElement("div", { className: "invoice-info-detail d-flex justify-content-between" },
                            react_1.default.createElement("div", { className: "info-item" },
                                t('Invoice Number'),
                                " :",
                                react_1.default.createElement("div", { className: "info" }, invoiceNumber)),
                            react_1.default.createElement("div", { className: "info-item" },
                                t('Invoice Date'),
                                " :",
                                react_1.default.createElement("div", { className: "info" }, localMoment(data.postingDateView, region).format("DD MMM YYYY")))),
                        react_1.default.createElement("div", { className: "invoice-info-date d-flex justify-content-between" },
                            react_1.default.createElement("div", { className: "info-item" },
                                t('Contract Period'),
                                " :",
                                react_1.default.createElement("div", { className: "info" },
                                    localMoment(data.contractStartDateView, region).format("DD MMM YYYY"),
                                    " - ",
                                    localMoment(data.contractEndDateView, region).format("DD MMM YYYY"))),
                            react_1.default.createElement("div", { className: "info-item" },
                                t('Due Date'),
                                " :",
                                react_1.default.createElement("div", { className: "info" }, localMoment(data.dueDateView, region).format("DD MMM YYYY"))))),
                    react_1.default.createElement("div", { className: "page-info-title d-flex flex-row" },
                        react_1.default.createElement("div", { className: "d-flex flex-column" },
                            react_1.default.createElement("div", { className: `property-name ${subbrand}` }, propertyBooking?.propertyBookingViewStr ? propertyBooking.propertyBookingViewStr : '-'),
                            react_1.default.createElement("div", { className: "unit-name mt-1" }, propertyBooking?.Room?.title ? propertyBooking?.Room?.title : '-'),
                            propertyBooking?.Room?.SubRoomInfo?.sub_unit_name ? (react_1.default.createElement("div", { className: "font-14px-regular" }, propertyBooking?.Room?.SubRoomInfo?.sub_unit_name)) : null),
                        (data?.paymentStatus === "Overdue" || data?.paymentStatus === "Pending") && data.blobUrlView ?
                            react_1.default.createElement("div", { className: "page-file-container flex-row invoice" },
                                react_1.default.createElement("a", { className: "d-flex flex-row", href: data.blobUrlView, target: "_blank" },
                                    react_1.default.createElement(react_1.default.Fragment, null,
                                        react_1.default.createElement("img", { src: "/images/react/icons/pdf_icon.svg" }),
                                        t("DOWNLOAD INVOICE"))))
                            : null)),
                react_1.default.createElement("div", { className: "page-info detail" },
                    react_1.default.createElement("div", { className: "divider" }),
                    react_1.default.createElement("div", { className: "detail-container d-flex flex-column" },
                        react_1.default.createElement("div", { className: "detail-title title" },
                            t("Outstanding Fee"),
                            ":"),
                        react_1.default.createElement(react_1.default.Fragment, null, invoiceItems.map((item, index) => (item?.billingScheduleTypeView?.toString() === '557130001' && (!item.billingScheduleSubTypeView || item.billingScheduleSubTypeView === '') ?
                            // rent which discount can be applied
                            react_1.default.createElement(react_1.Fragment, { key: index },
                                react_1.default.createElement("div", { className: "detail-item license-fee d-flex flex-row", key: index },
                                    react_1.default.createElement("div", { className: "detail-item-title-container d-flex flex-column" },
                                        react_1.default.createElement("div", { className: "detail-item-title major d-flex flex-row justify-content-start align-items-center" },
                                            react_1.default.createElement("div", { className: "title" }, region === 'jp' ? t("Rent") : t("License Fee"))),
                                        item.invoicePeriodFromView && item.invoicePeriodToView ?
                                            react_1.default.createElement("div", { className: "detail-item-title minor" },
                                                "From ",
                                                localMoment(item.invoicePeriodFromView, region).format("DD/MM/YYYY"),
                                                " to ",
                                                localMoment(item.invoicePeriodToView, region).format("DD/MM/YYYY"))
                                            : null),
                                    react_1.default.createElement("div", { className: "detail-item-result cost" },
                                        currency,
                                        (0, CommonHelpers_1.formatPrice)(item.totalAmountView))),
                                paymentDiscount ?
                                    react_1.default.createElement("div", { className: "sub-row-bracket detail-item license-fee-discount d-flex flex-row" },
                                        react_1.default.createElement("div", { className: "detail-item-title-container d-flex flex-column" },
                                            react_1.default.createElement("div", { className: "detail-item-title major d-flex flex-row justify-content-start align-items-center" },
                                                react_1.default.createElement("div", { className: "title d-flex" },
                                                    react_1.default.createElement("span", null, region === 'jp' ? t('Rent Discount') : t('License Fee Discount')),
                                                    " (",
                                                    react_1.default.createElement("div", { className: "hightlight" },
                                                        paymentDiscount,
                                                        "% off"),
                                                    ")"))),
                                        react_1.default.createElement("div", { className: "detail-item-result cost" },
                                            "-",
                                            currency,
                                            (0, CommonHelpers_1.formatPrice)(item.totalAmountView * paymentDiscount / 100)))
                                    : null)
                            :
                                react_1.default.createElement("div", { className: "detail-item d-flex flex-row", key: index },
                                    react_1.default.createElement("div", { className: "detail-item-title-container d-flex flex-column" },
                                        react_1.default.createElement("div", { className: "detail-item-title major d-flex flex-row justify-content-start align-items-center" },
                                            react_1.default.createElement("div", { className: "title" }, item.billingScheduleSubTypeView || item.typeView)),
                                        item.billingScheduleSubTypeView && item.billingScheduleSubTypeView.toLowerCase() === "security deposit" ?
                                            react_1.default.createElement("div", { className: "detail-item-title minor" }, region === 'jp'
                                                ? propertyBooking?.Room?.WebSiteRoom.pet ? (t("Equivalent to 1-month rent")) : (t("Equivalent to 1-month rent"))
                                                : propertyBooking?.Room?.WebSiteRoom.pet ? (t("Equivalent to 1-month license fee")) : (t("Equivalent to 1-month license fee")))
                                            : null,
                                        item.invoiceDescriptionView ? (react_1.default.createElement("div", { className: "detail-item-title minor" }, item.invoiceDescriptionView)) : null,
                                        item.extensionStartDateView && item.extensionEndDateView ? (react_1.default.createElement("div", { className: "detail-item-title minor" },
                                            localMoment(item.extensionStartDateView, region).format("DD/MM/YYYY"),
                                            " - ",
                                            localMoment(item.extensionEndDateView, region).format("DD/MM/YYYY"))) : null),
                                    react_1.default.createElement("div", { className: "detail-item-result cost" },
                                        currency,
                                        (0, CommonHelpers_1.formatPrice)(item.totalAmountView))))))),
                    react_1.default.createElement("div", { className: "divider" }),
                    react_1.default.createElement("div", { className: "total-result d-flex flex-row" },
                        react_1.default.createElement("div", { className: "result-title title" },
                            t("Total"),
                            ":"),
                        react_1.default.createElement("div", { className: "result total-cost" },
                            currency,
                            (0, CommonHelpers_1.formatPrice)(total)))))),
        data?.receiptUrlView && data?.paymentStatus === "Paid" ?
            react_1.default.createElement("div", { className: "page-file-container flex-row receipt" },
                react_1.default.createElement("a", { className: "d-flex flex-row", href: data.receiptUrlView, target: "_blank" },
                    react_1.default.createElement(react_1.default.Fragment, null,
                        react_1.default.createElement("img", { src: "/images/react/icons/pdf_icon.svg" }),
                        t("DOWNLOAD RECEIPT"))))
            : null);
};
exports.PaymentDetail = PaymentDetail;
