"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentOption = void 0;
var PaymentOption;
(function (PaymentOption) {
    PaymentOption["fps"] = "FPS";
    PaymentOption["payme"] = "PayMe";
    PaymentOption["wechat_pay"] = "Wechat Pay";
    PaymentOption["alipay"] = "Alipay";
    PaymentOption["alipayhk"] = "AlipayHK";
    PaymentOption["unionpay"] = "Unionpay";
    PaymentOption["credit_card"] = "Credit Card";
    PaymentOption["bank_transfer"] = "Bank Transfer";
})(PaymentOption = exports.PaymentOption || (exports.PaymentOption = {}));
