"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Select = void 0;
const react_1 = __importStar(require("react"));
require("../assets/scss/components/Select.scss");
const reactstrap_1 = require("reactstrap");
function Select(props) {
    const { label, name, options, className, disabled, hasLabel, onChange, defaultValue, value } = props;
    const lableActive = hasLabel ? 'active' : 'inactive';
    const [isFocus, setIsFocus] = (0, react_1.useState)(false);
    const [currentValue, setCurrentValue] = (0, react_1.useState)(defaultValue ? defaultValue : null);
    const dropDownOnToggle = (e) => {
        const target = e?.target?.className;
        setIsFocus(!isFocus);
    };
    (0, react_1.useEffect)(() => {
        if (defaultValue) {
            setCurrentValue(defaultValue);
        }
    }, [defaultValue]);
    return (react_1.default.createElement("div", { className: `${name} select-container ${className} ${disabled ? 'disabled' : ''}` },
        react_1.default.createElement(reactstrap_1.Dropdown, { isOpen: isFocus && !disabled, toggle: dropDownOnToggle, className: `${name} ${className} select-group` },
            react_1.default.createElement(reactstrap_1.DropdownToggle, { onClick: dropDownOnToggle, className: `${name} select-group-button` },
                react_1.default.createElement("div", { className: `${name} ${(currentValue || value) ? 'selected' : 'field-label'} select-group-label` }, value
                    ? options.find(val => val.value === value)?.label
                    : currentValue
                        ? options.find(val => val.value === currentValue)?.label
                        : label),
                react_1.default.createElement("div", { className: 'arrow-icon-container' },
                    react_1.default.createElement("div", { className: 'select-drop-down-arrow' }))),
            react_1.default.createElement(reactstrap_1.DropdownMenu, { className: `${name} select-options` }, options.map((option) => (react_1.default.createElement(reactstrap_1.DropdownItem, { key: option.value, value: option.value, className: `${name} select-option`, onClick: () => { onChange?.(option.value); setCurrentValue(option.value); } }, option.label))))),
        react_1.default.createElement("label", { className: `pop-up-label ${name} ${currentValue ? lableActive : 'inactive'}` }, label)));
}
exports.Select = Select;
