"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchClickConpletedAction = exports.SearchClickFormCategory = exports.LisenceFormConpletedAction = exports.LisenceFormCategory = exports.IdentificationFormConpletedAction = exports.IdentificationFormCategory = exports.SignUpFormCompletedAction = exports.SignUpFormCategory = exports.PurchaseFormCompletedAction = exports.PurchaseFormCategory = exports.loginFormCompletedAction = exports.LoginFormCategory = void 0;
// login
const LoginFormCategory = "login";
exports.LoginFormCategory = LoginFormCategory;
const loginFormCompletedAction = "";
exports.loginFormCompletedAction = loginFormCompletedAction;
// purchase
const PurchaseFormCategory = "purchase";
exports.PurchaseFormCategory = PurchaseFormCategory;
const PurchaseFormCompletedAction = "";
exports.PurchaseFormCompletedAction = PurchaseFormCompletedAction;
// sign up
const SignUpFormCategory = "sign_up";
exports.SignUpFormCategory = SignUpFormCategory;
const SignUpFormCompletedAction = "";
exports.SignUpFormCompletedAction = SignUpFormCompletedAction;
// submits passport
const IdentificationFormCategory = "identification";
exports.IdentificationFormCategory = IdentificationFormCategory;
const IdentificationFormConpletedAction = "";
exports.IdentificationFormConpletedAction = IdentificationFormConpletedAction;
// lisense aggrement
const LisenceFormCategory = "lisense";
exports.LisenceFormCategory = LisenceFormCategory;
const LisenceFormConpletedAction = "";
exports.LisenceFormConpletedAction = LisenceFormConpletedAction;
// search click
const SearchClickFormCategory = "search_click";
exports.SearchClickFormCategory = SearchClickFormCategory;
const SearchClickConpletedAction = "";
exports.SearchClickConpletedAction = SearchClickConpletedAction;
