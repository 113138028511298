// window.$ = window.jQuery = require("jquery");
window.$ = window.jQuery = require("jquery");
require("jquery-ui-bundle");
require("jquery-ui-touch-punch");
import "jquery-ui/themes/base/all.css";
require("bootstrap/dist/js/bootstrap.bundle");
require("masonry-layout/dist/masonry.pkgd.min.js");

// require('jquery');
// require("bootstrap");

// require('bootstrap-multiselect/dist/js/bootstrap-multiselect');
window.Popper = require("popper.js").default;

require("flatpickr");
require("select2");

const flatpickr = window.flatpickr;

window.ChangePrice = function (e, type, id) {
  const rental = $(e).attr("data-rental-rate");
  const price_class = $(e).attr("data-price-class");

  $(e).closest(".price-container").find(".price > div").hide();
  $(e).closest(".price-container").find(`.price .price-${price_class}`).show();

  $(e)
    .closest(".price-container")
    .find(".price-select .price-select-container")
    .removeClass("active");
  $(e)
    .closest(".price-container")
    .find(`.price-select .price-select-container.${price_class}`)
    .addClass("active");

  if ($(".modal-bookbtn")) {
    $(".modal-bookbtn").attr("data-rental-rate", rental);
  }
  $("#rental_period").val(rental);

  if (type === "nightly") {
    // book btn
    $("#roomModal-" + id)
      .find(".more-btn a.nightly")
      .show();
    $("#roomModal-" + id)
      .find(".more-btn div.months")
      .hide();

    // tag text
    $("#roomModal-" + id)
      .find(".tag-container.nightly")
      .show();
    $("#roomModal-" + id)
      .find(".tag-container.months")
      .hide();

    // learn more btn
    $("#roomModal-" + id)
      .find(".more-btn a.learn-btn")
      .show();
    $("#roomModal-" + id)
      .find(".more-btn a.explore-btn")
      .hide();
  } else {
    // book btn
    $("#roomModal-" + id)
      .find(".more-btn a.nightly")
      .hide();
    $("#roomModal-" + id)
      .find(".more-btn div.months")
      .show();

    // tag text
    $("#roomModal-" + id)
      .find(".tag-container.nightly")
      .hide();
    $("#roomModal-" + id)
      .find(".tag-container.months")
      .show();

    // learn more btn
    $("#roomModal-" + id)
      .find(".more-btn a.learn-btn")
      .hide();
    $("#roomModal-" + id)
      .find(".more-btn a.explore-btn")
      .show();
  }
  $(`.room-dc-badge-container#room-badge-${id}`)
    .removeClass("show")
    .removeClass("hide");
  if (price_class === "nightly" || price_class === "flexi") {
    $(`.room-dc-badge-container.nv-coupon#room-badge-${id}`).addClass("show");
    $(`.room-dc-badge-container.vf-coupon#room-badge-${id}`).addClass("hide");
  } else if (price_class === "months" || price_class === "years") {
    $(`.room-dc-badge-container.vf-coupon#room-badge-${id}`).addClass("show");
    $(`.room-dc-badge-container.nv-coupon#room-badge-${id}`).addClass("hide");
  }
};

/**
 * Update language switcher - after ajax
 */
window.updateLanguageUrl = function () {
  let current_url = window.location.href;
  let current_pathname = window.location.pathname;

  let current_locale = current_pathname.split("/")[1];
  let current_region = current_pathname.split("/")[2];

  $(".dropdown-language .dropdown-item").each(function () {
    const locale = $(this).data("locale");
    const region = $(this).data("region");

    let new_url = current_url.replace(`/${current_locale}/`, `/${locale}/`);
    new_url = new_url.replace(`/${current_region}/`, `/${region}/`);

    $(this).attr("href", new_url); //desktop
    $(`.nav-lang[data-locale=${locale}]`).attr("href", new_url); //mobile
  });
};

const el = document.querySelector("#float-btn");
const observer = new IntersectionObserver(
  ([e]) => e.target.classList.toggle("is-pinned", e.intersectionRatio < 1),
  { threshold: [1] }
);

observer.observe(el);

$(document).ready(function () {
  $(".dropdown").mouseenter(function () {
    // console.log("hover .dropdown");
    var dropdown = $(this).children(".dropdown-toggle");
    $(dropdown).dropdown("toggle");
  });

  window.initalDatepicker = function (movein_date, preferredviewing_date) {
    flatpickr("#movein", {
      // mode: "single",
      dateFormat: "Y-m-d",
      minDate: movein_date["min"],
      maxDate: movein_date["max"],
    });

    flatpickr(".date-picker", {
      mode: "range",
      dateFormat: "Y-m-d",
      minDate: preferredviewing_date["min"],
      maxDate: preferredviewing_date["max"],
    });
  };
});

// $( ".navbar-toggler" ).click(function() {
//     // alert( "Handler for .click() called." );
//     $(".navbar-home").toggleClass("show-m");
//     $('.navbar-brand').toggleClass('hide');

//   });

// icons tootip
$(function () {
  $('[data-toggle="tooltip"]').tooltip();
});

$(".cta-btn").click(function () {
  modal_book_btn(this);
  // console.log("Booknow button onclick");
  const url = $(this).attr("data-scheduletour-url");
  if (url) {
    $("#iframeModal-2").modal("show");
    $("#iframeModal-2").find("iframe").attr("src", url);
  }
});
$(".roomSpecModal .cta-btn").click(function () {
  console.log("room spec click");
  $("#roomSpecModal").modal("hide");
});

//booking
$("nav .btn.book-now").click(function () {
  $(".navbar-toggler-icon").click();
});
$(".booking-panel-wrap .item").click(function () {
  $("#bookingModal").modal("hide");
});
$(".booking-panel-wrap [data-type='iframe']").click(function () {
  var src = $(this).attr("data-src");
  var iframe = $("#iframeModal iframe");

  iframe.attr("src", src);
  $("#iframeModal").modal("show");
});
$("#iframeModal").on("hidden.bs.modal", function (e) {
  var iframe = $("#iframeModal iframe");
  iframe.attr("src", "");
});

//fix menu dropdown
$(".dropdown-menu").mouseleave(function () {
  // console.log("mouse leave: dropdown")
  $(".dropdown, .dropdown-menu").removeClass("show");
});

//mobile menu
$.fn.overflown = function () {
  var e = this?.[0];
  if (
    typeof e?.scrollHeight !== "undefined" &&
    typeof e?.clientHeight !== "undefined" &&
    e.scrollHeight !== null &&
    e.clientHeight !== null
  ) {
    return e.scrollHeight > e.clientHeight;
  }

  if (
    typeof e?.scrollWidth !== "undefined" &&
    typeof e?.clientWidth !== "undefined" &&
    e.scrollWidth !== null &&
    e.clientWidth !== null
  ) {
    return e.scrollWidth > e.clientWidth;
  }
};

$(document).ready(function () {
  mobileMenuController();
});
function mobileMenuController() {
  var overflown = $(".nav-wrap").overflown();
  if (overflown) {
    //not enough space, collapse menu
    $('[data-target="#collapse-menu-1"]').click();
  } else {
    //enough space, distribute height
    // var child = $(".mobile-nav-main .nav-link")
    // var child_length = child.length;
    // var parent_height = $(".mobile-nav-main").height();
    // var new_height = (parent_height / child_length);
    // child.each(function () {
    //     var mt = $(this).css("margin-top");
    //     var mb = $(this).css("margin-bottom");
    //     mt = mt.substring(0, mt.length - 2);
    //     mb = mb.substring(0, mb.length - 2);
    //     var h = (new_height - mt - mb) + 'px';
    //     $(this).css("height", h);
    // })
  }
}
$(".navbar-toggler").click(function () {
  // alert( "Handler for .click() called." );
  if ($("html").css("overflow") == "hidden") {
    // true
    $("html").css("overflow", "");
  } else {
    $("html").css("overflow", "hidden");
  }
});

$(".nav-header img.menu-close").click(function () {
  $("html").css("overflow", "");
});

window.togglePriceTier = function (e, type, slug, isSoldout, haveTag) {
  console.log('window.togglePriceTier');
  $(e).closest(".toggle-btn-container").find("button").removeClass("active");
  $(e).addClass("active");

  if (type === "monthly") {
    $(e)
      .closest(".toggle-btn-container")
      .find(".indicator")
      .removeClass("move-right");
    // book btn
    // $('#room-card-bottom-panel-' + slug).find('#btn-book-now').hide();

    // learn more btn
    $("#room-card-bottom-panel-" + slug)
      .find(".btn.more.month")
      .show();
    $("#room-card-bottom-panel-" + slug)
      .find(".nightly-learn-more")
      .hide();

    $("#promotion-room-card-" + slug)
      .find(".promotion-item-tag.nightly")
      .hide();
    if (isSoldout) {
      // monthlys are sold out

      // add bg in monthly
      $("#promotion-room-card-" + slug).addClass("with-layer");
      $("#promotion-room-card-" + slug)
        .find(".bg")
        .addClass("with-layer");
      // show sold out tag in monthlys
      $("#promotion-room-card-" + slug)
        .find(".promotion-item-tag.monthly")
        .show();
    } else {
      // not monthlys are sold out

      // rm bg in monthly
      $("#promotion-room-card-" + slug).removeClass("with-layer");
      $("#promotion-room-card-" + slug)
        .find(".bg")
        .removeClass("with-layer");

      // hide sold out tag in monthlys
      $("#promotion-room-card-" + slug)
        .find(".promotion-item-tag.monthly")
        .hide();
      if (haveTag) {
        $("#promotion-room-card-" + slug)
          .find(".promotion-item-tag.monthly")
          .show();
      }
    }
    // change price
    $(e).closest(".price-container").find(`.price-monthly`).show();
    $(e).closest(".price-container").find(`.price.main.price-short`).hide();
  } else {
    $(e)
      .closest(".toggle-btn-container")
      .find(".indicator")
      .addClass("move-right");
    // book btn
    // $('#room-card-bottom-panel-' + slug).find('#btn-book-now').css('display', 'flex');

    // learn more btn
    // $('#room-card-bottom-panel-' + slug).find('.btn.more.month').hide();
    // $('#room-card-bottom-panel-' + slug).find('.nightly-learn-more').show();

    $("#promotion-room-card-" + slug)
      .find(".promotion-item-tag.monthly")
      .hide();
    if (isSoldout) {
      // night sold out

      // add bg in nightly
      $("#promotion-room-card-" + slug).addClass("with-layer");
      $("#promotion-room-card-" + slug)
        .find(".bg")
        .addClass("with-layer");

      // show sold out tag in nightly
      $("#promotion-room-card-" + slug)
        .find(".promotion-item-tag.nightly")
        .show();
    } else {
      // not night sold out

      // rm bg in nightly
      $("#promotion-room-card-" + slug).removeClass("with-layer");
      $("#promotion-room-card-" + slug)
        .find(".bg")
        .removeClass("with-layer");

      // hide sold out tag in nightly
      $("#promotion-room-card-" + slug)
        .find(".promotion-item-tag.nightly")
        .hide();
    }
    // change price
    $(e).closest(".price-container").find(`.price-monthly`).hide();
    $(e).closest(".price-container").find(`.price.main.price-short`).show();
  }

  // change tier text
  $(e).closest(".price-container").find(".price.tier").hide();
  $(e).closest(".price-container").find(`.price.price-tier-${type}`).show();
};

// import react components
require("./react");
